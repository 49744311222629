<template>
  <div v-if="users.paginator">
    <base-material-card
      color="default"
      icon="mdi-account-outline"
      inline
      class="px-5 py-3"
      :elevation="8"
    >
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users.records"
          :loading="isLoading"
          :hide-default-footer="true"
          :search="searchQuery"
          transition="scale-transition"
        >
          <template v-slot:top>
            <v-toolbar-title>
              <v-row>
                <h1 class="ml-4 my-4">Employees</h1>
              </v-row></v-toolbar-title
            >
            <v-carousel
              :dark="$vuetify.theme.dark"
              :light="!$vuetify.theme.dark"
              hide-delimiter-background
              show-arrows-on-hover
              height="150"
              class="card-slider"
            >
              <v-carousel-item>
                <v-row class="my-10">
                  <v-col cols="12" sm="3" md="3" lg="3">
                    <download-excel
                      class="text-right"
                      :data="users.records"
                      :fields="fields"
                      worksheet="My Worksheet"
                      name="FairwageListofUsers.xls"
                    >
                      <v-btn class="text-right" color="#0a2752" large dark>
                        <h1>Download</h1>
                      </v-btn>
                    </download-excel>
                  </v-col>
                  <!-- <v-col cols="12" sm="3" md="3" lg="3">
                    <v-dialog v-model="dialog" max-width="900px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          color="primary"
                          large
                          v-bind="attrs"
                          v-on="on"
                        >
                          <h1>Bulk Employee Upload</h1>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Bulk Employee Upload</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <user-bulk-create />
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col> -->

                  <v-col cols="12" sm="3" md="3" lg="3">
                    <v-dialog v-model="dialog3" width="800">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red lighten-2"
                          dark
                          large
                          v-bind="attrs"
                          v-on="on"
                        >
                          <h1>Create New Employee</h1>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          <h1>Create New Employee</h1>
                        </v-card-title>

                        <v-card-text>
                          <user-create />
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="dialog3 = false">
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <!-- </v-toolbar> -->
          </template>
          <template v-slot:item.firstName="{ item }">
            <p color="primary">
              <router-link :to="'/admin/user/' + item.id + '/edit'"
                >{{ item.firstName }} {{ item.lastName }}</router-link
              >
            </p>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <p>
              <span>Created At:</span>
              <span color="primary">{{
                item.createdAt | getFormattedDate
              }}</span>
            </p>
            <p>
              <span color="primary">Updated At:</span>
              <span color="primary">{{
                item.updatedAt | getFormattedDate
              }}</span>
            </p>
          </template>

          <template v-slot:item.communications="{ item }">
            <v-btn fab small @click="whatsappUser(item)">
              <img
                width="40px"
                src="@/assets/whatsapp.png"
                alt="avatar"
                align="center"
                justify="center"
              />
            </v-btn>
            <base-user-contact :user="item" :me="me" />
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              elevation="3"
              x-small
              dark
              class="ml-2"
              @click="editItem(item)"
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              fab
              elevation="3"
              x-small
              dark
              class="ml-2"
              @click="deleteItem(item)"
              color="error"
              v-if="me.role === 'admin'"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <v-row>
      <v-col cols="12" sm="6" lg="11" class="mt-2 text-right mr-0 pr-0">
        <span class="mr-4 grey--text">
          Page {{ page }} of {{ numberOfPages }}
        </span>
        <v-btn fab small color="primary" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab small color="primary" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

import UserCreate from "../../../components/Base/User/UserCreate";
// import { mapState } from 'vuex'
export default {
  name: "ViewUsers",
  components: {
    UserCreate,
  },
  data() {
    return {
      searchQuery: "",
      items: [],
      fields: {
        "First Name": "firstName",
        "Last Name": "lastName",
        "Email Address": "email",
        "Mobile Number": "telNumber",
        // Referral: "referral",
        "Consultant ID": "consultant",
        "Created At": "createdAt",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "firstName",
          //filter: (value) => {
          // if (!this.userQuery) return true;
          // return value === this.userQuery;
          // },
        },
        { text: "Email", align: "start", value: "email" },

        { text: "Number", value: "telNumber", align: "left" },

        {
          text: "Communications",
          align: "left",
          sortable: false,
          value: "communications",
        },

        { text: "Actions", align: "left", sortable: false, value: "actions" },
      ],
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      search: [],
      users: [],
      totalRecords: 0,
      isLoading: false,
      page: 1,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          firstName
          lastName
          role
          #branch
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
              # fullAddress
              telNumber
              # altNumber
              # branch
              # referral
              # title
              # consultant
              # dob
              #consultant
              # branchName
              role
              createdAt
              updatedAt
            }
            paginator {
              userCount
              perPage
              pageCount
              currentPage
              slNo
              hasNextPage
              hasPrevPage
              next
              prev
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 10;
        return {
          limit: limit,
          page: this.page,
          query: { role: "employee" },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  computed: {
    numberOfPages() {
      return this.users.paginator.pageCount;
    },
    filterItems() {
      if (this.searchQuery == "") {
        this.items = this.users.records;
      } else {
        this.fetchMore();
      }
    },
  },
  methods: {
    whatsappUser(item) {
      let href =
        "https://api.whatsapp.com/send?phone=`+`27810137575&text=Hi%20I'm%20a%20Fairwage%20Consultant%20and%20just%20wanted%20to%20make%20sure%20everything%20is%20going%20well?";
      window.open(href);
    },

    editItem(item) {
      this.$router.push(`/admin/user/${item.id}/`);
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert deleting this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteUser(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });
            if (response) {
              this.$swal(
                "Success!",
                "Employee was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
            console.log(`Login Failed ${JSON.stringify(e)}`);
            self.errorMsg = e.message;
          }
        }
      });
    },
    // Pagination functions
    refreshData() {
      console.log("Refreshing data for users");
      this.$apollo.queries.users.refetch();
      this.$apollo.queries.me.refetch();
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.users.paginator.perPage = number;
    },
    removeFalsy(obj) {
      const newObj = {};
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    },
    async fetchMore() {
      const query = gql`
        query Search($filter: String, $limit: Int, $page: Int, $query: JSON!) {
          search(filter: $filter, limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
              fullAddress
              telNumber
              altNumber
              # branch
              referral
              title
              consultant
              # dob

              # branchName
              role
              createdAt
              updatedAt
            }
          }
        }
      `;
      const filteredUsers = await this.$apollo.query({
        query: query,
        variables: {
          limit: 10,
          page: this.page,
          query: { role: "employee" },
          filter: this.searchQuery,
        },
      });
      this.items = filteredUsers.data.search.records;
      return this.items;
    },
  },
};
</script>

<style lang="scss" scoped></style>
